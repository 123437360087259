/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import Company from "../../../assets/images/Header/Company.png";
import Person from "../../../assets/images/Header/Person.png";

function BodyBottomContent({ offerData, pdfLang, issueFrom }) {
  const styles = StyleSheet.create({
    container: {
      marginVertical: 20,
      fontSize: 10,
      position: "relative",
      direction: pdfLang === "1" ? "ltr" : "rtl",
      textAlign: pdfLang === "1" ? "left" : "right",
    },
    flex: {
      display: "flex",
      flexDirection: pdfLang === "1" ? "row" : "row-reverse",
      flexWrap: "wrap",
      marginVertical: 5,
    },
    underline: {
      fontStyle: "bold",
      textDecoration: "underline",
      fontSize: 10,
    },
    rate: {
      width: "20%",
      padding: 10,
    },
    stamp: {
      position: "absolute",
      bottom: -115,
      left: pdfLang === "1" ? "auto" : 0,
      right: pdfLang === "1" ? 10 : "auto",
      zIndex: 5,
      display: "flex",
      width: 200,
    },
    point: {
      width: 4,
      height: 4,
      backgroundColor: "#000",
      borderRadius: "50%",
      marginTop: 4,
      marginHorizontal: 4,
    },
    textWithMargin: {
      marginHorizontal: 2,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.flex}>
        {pdfLang === "1" ? (
          <Text>*The company is subject to Advance Payment Tax System</Text>
        ) : (
          <View style={styles.flex}>
            <Text style={styles.textWithMargin}>*</Text>
            <Text style={styles.textWithMargin}>
              تخضع الشركة لنظام الدفعات المقدمة
            </Text>
          </View>
        )}
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text style={[styles.underline, styles.textWithMargin]}>
          {pdfLang === "1" ? "Price offer validity:" : " : صلاحيه عرض السعر"}
        </Text>
        {pdfLang === "1" ? (
          <Text>Price offer valid for</Text>
        ) : (
          <Text style={styles.textWithMargin}> عرض السعر سارى لمده </Text>
        )}
        <Text style={styles.textWithMargin}>
          {offerData?.PriceOfferValidity}
        </Text>
        <Text style={styles.textWithMargin}>
          {pdfLang === "1" ? "Hours" : "ساعة"}
        </Text>
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text style={{ ...styles.underline, ...styles.textWithMargin }}>
          {pdfLang === "1" ? "Payment terms:" : " : شروط الدفع"}
        </Text>
        <Text style={styles.textWithMargin}>
          {offerData?.PaymentTerms?.Name}
        </Text>
        <Text style={styles.textWithMargin}>,</Text>
        <Text style={styles.textWithMargin}>
          {pdfLang === "1"
            ? "Bank transfer or payable check received"
            : "نقدا او بتحويل بنكي او من خلال شيك"}
        </Text>
        <Text style={styles.textWithMargin}>
          {offerData?.ExptectedDateToRecievePaymentCheck}
        </Text>
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text style={styles.textWithMargin}>
          {pdfLang === "1"
            ? "Prices don’t include VAT 14%"
            : " 14% الأسعار غير شاملة القيمة المضافة "}
        </Text>
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text style={styles.textWithMargin}>
          {pdfLang === "1"
            ? "Prices stated are valid unless there is no price change by the production companies, provided availability of products at the production companies"
            : "الأسعار سارية ما لم يكن هناك تغيير في الأسعار من قبل الشركات المنتجة مع شروط توافر المنتجات لدى الشركة المنتجة"}
        </Text>
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text style={{ whiteSpace: "pre-wrap", marginBottom: 5 }}>
          {offerData?.Notes}
        </Text>
      </View>

      <View style={styles.flex}>
        <Text style={styles.textWithMargin}>
          {pdfLang === "1"
            ? "If you have any questions or comments, please don’t hesitate to contact us"
            : "في حال وجود أي أسئلة أو ملاحظات يرجى عدم التردد في الاتصال بنا"}
        </Text>
      </View>

      <View style={styles.flex}>
        <Text>
          {offerData?.SalesRepresentitiveName}
          {offerData?.SalesRepresentitiveNumber}
        </Text>
      </View>

      <Image
        src={issueFrom === "1" ? Person : Company}
        alt=""
        style={styles.stamp}
      />
    </View>
  );
}

export default BodyBottomContent;
